.root {
  height: 3508px;
  width: 2480px;
}

.grid {
  position: absolute;
  top: 0;
  z-index: 0;
  left: 0;
  height: 3508px !important;
  width: 2480px;
  background: transparent;
  display: grid;
  grid-template-rows: 10% repeat(4, 1fr) 10%;
}

.gridElement {
  border: 1px solid yellow;
  display: grid;
  grid-template-columns: 10% 40% 40% 10%;
}

.gridColumn {
  border: 1px solid red;
}

.debugPanel {
  position: fixed;
  top: 8px;
  left: 8px;
  display: flex;
  gap: 8px;
}

.button {
  cursor: pointer;
  background: white;
  color: black;
  font-size: 14px;
  border-radius: 12px;
  padding: 7px 15px 7px;
  border: 1px solid black;
}

.button:hover {
  background: #ece8e8;
}
