.root {
  width: 100vw;
  height: 100vh;
}

.wrapper {
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.canvas {
  width: 2480px;
  height: 3508px;
}

.canvas_absolute {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
